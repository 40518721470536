<template>
  <div class="popup" style="font-size: 13pt">
    <div v-for="(tr, indextr) in cn_supplier" :data="tr" :key="indextr">
      <div style="margin-bottom: 2%">
        <div style="justify-center: end; display: flex">
          <div style="width: 100%" align="center">
            <h3><b>CREDIT NOTE</b></h3>
          </div>
        </div>
      </div>
      <br />
      <div class="d-flex">
        <div style="width: 50%">
          <table width="100%">
            <tbody>
              <tr>
                <td style="vertical-align: top" width="35%">Vendor Name</td>
                <td style="vertical-align: top" width="2%">:</td>
                <td style="vertical-align: top" width="63%">
                  {{ supplier.Name.replace("(GT)", "").replace("(LMT)", "") }}
                </td>
              </tr>
              <tr style="">
                <td style="vertical-align: top">Vendor Address</td>
                <td style="vertical-align: top">:</td>
                <td style="vertical-align: top">{{ supplier.Address }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top">Vendor NPWP</td>
                <td style="vertical-align: top">:</td>
                <td style="vertical-align: top">{{ supplier.Npwp }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="width: 50%">
          <table width="100%">
            <tbody>
              <tr>
                <td style="vertical-align: top" width="40%">Credit Note No</td>
                <td style="vertical-align: top" width="2%">:</td>
                <td style="vertical-align: top" width="58%">{{ data.code }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top">Credit Note Date</td>
                <td style="vertical-align: top">:</td>
                <td style="vertical-align: top">{{ dateFormat(data.date) }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top">Reference</td>
                <td style="vertical-align: top">:</td>
                <td style="vertical-align: top">{{ data.reference }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <br />
      <div class="d-flex">
        <div style="width: 50%">
          <table width="100%">
            <tbody>
              <tr>
                <td style="vertical-align: top" width="35%">Purchase Org Name</td>
                <td style="vertical-align: top" width="2%">:</td>
                <td style="vertical-align: top" width="63%">{{ company.Name }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top">Purchase Org Address</td>
                <td style="vertical-align: top">:</td>
                <td style="vertical-align: top">{{ company.Address }}</td>
              </tr>
              <tr>
                <td style="vertical-align: top">Purchase Org NPWP</td>
                <td style="vertical-align: top">:</td>
                <td style="vertical-align: top">{{ company.Npwp }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div style="width: 50%"></div>
      </div>

      <br />
      <div>
        <div>
          <table
            class="padding-table"
            style="width: 100%; border-collapse: collapse"
            v-if="tr.manualLines.length == 0"
          >
            <tbody>
              <tr class="text-center">
                <td style="border: 1px solid black">
                  <strong>No</strong>
                </td>
                <td style="border: 1px solid black">
                  <strong>Description</strong>
                </td>
                <td style="border: 1px solid black">
                  <strong>Amount</strong>
                </td>
                <td style="border: 1px solid black">
                  <strong>Vat (PPN)</strong>
                </td>
                <td style="border: 1px solid black">
                  <strong>Total Amount</strong>
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    padding-left: 12px;
                  "
                >
                  1
                </td>
                <td style="border-left: 1px solid black; border-right: 1px solid black">
                  {{ data.description }}
                </td>
                <td
                  style="border-left: 1px solid black; border-right: 1px solid black"
                  align="right"
                >
                  <span>{{ priceFormat(data.value_credit) }}</span>
                </td>
                <td
                  style="border-left: 1px solid black; border-right: 1px solid black"
                  align="right"
                >
                  {{ priceFormat(data.vat_in_value ? data.vat_in_value : 0) }}
                </td>
                <td
                  style="border-left: 1px solid black; border-right: 1px solid black"
                  align="right"
                >
                  {{ priceFormat(data.value_credit) }}
                </td>
              </tr>
              <tr>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>Total</td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    text-align: right;
                  "
                >
                  {{ priceFormat(data.total_credit) }}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            class="padding-table"
            style="width: 100%; border-collapse: collapse"
            v-else
          >
            <tbody>
              <tr class="text-center">
                <td style="border: 1px solid black">
                  <strong>No</strong>
                </td>
                <td style="border: 1px solid black">
                  <strong>Description</strong>
                </td>
                <td style="border: 1px solid black">
                  <strong>Amount</strong>
                </td>
                <td style="border: 1px solid black">
                  <strong>Vat (PPN)</strong>
                </td>
                <td style="border: 1px solid black">
                  <strong>Total Amount</strong>
                </td>
              </tr>
              <template v-for="(dtLine, dtIndex) in tr.manualLines">
                <tr :key="dtIndex" v-if="tr.manualLines">
                  <td style="border-left: 1px solid black; border-right: 1px solid black">
                    <!-- {{ dtIndex+1 }} -->
                    {{ dtIndex + 1 + tr.chunkOffset }}
                  </td>
                  <td style="border-left: 1px solid black; border-right: 1px solid black">
                    <!-- {{ dt.item_name }} ({{ dt.unit_name }}) -->
                    {{ dtLine.description }}
                  </td>
                  <td
                    style="border-left: 1px solid black; border-right: 1px solid black"
                    align="right"
                  >
                    <!-- {{ priceFormat(dt.dn_value) }} -->
                    {{ dtLine.is_pph ? "(" : "" }}{{ priceFormat(dtLine.value_credit)
                    }}{{ dtLine.is_pph ? ")" : "" }}
                  </td>
                  <td
                    style="border-left: 1px solid black; border-right: 1px solid black"
                    align="right"
                  >
                    <!-- {{ priceFormat(dt.dn_tax) }} -->
                    {{ priceFormat(dtLine.vat_in_value ? dtLine.vat_in_value : 0) }}
                  </td>
                  <td
                    style="border-left: 1px solid black; border-right: 1px solid black"
                    align="right"
                  >
                    <!-- {{ priceFormat(dt.dn_total_value) }} -->
                    <!-- <p v-if="dtLine.vat_in">
                      {{ priceFormat(dtLine.value_debit) }}
                    </p> -->
                    <p>
                      {{ dtLine.is_pph ? "(" : ""
                      }}{{
                        priceFormat(
                          dtLine.value_credit
                            ? parseFloat(dtLine.value_credit) +
                                (dtLine.vat_in_value
                                  ? parseFloat(dtLine.vat_in_value)
                                  : 0)
                            : 0
                        )
                      }}{{ dtLine.is_pph ? ")" : "" }}
                    </p>
                  </td>
                </tr>
              </template>
              <tr>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                  "
                ></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td>TOTAL</td>
                <td
                  style="
                    border-left: 1px solid black;
                    border-right: 1px solid black;
                    border-bottom: 1px solid black;
                    text-align: right;
                  "
                >
                  {{ priceFormat(parseFloat(tr.total_credit) - parseFloat(tr.totalPPH)) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        style="margin-top: 5%; margin-left: 5%"
        v-if="indextr == cn_supplier.length - 1"
      >
        <table width="100%">
          <tbody>
            <tr>
              <!-- <td width="15%">Yang Menerima,</td> -->
              <td width="15%">Yang Menerima,</td>
              <td width="25%"></td>
              <td width="30%">Hormat Kami</td>
              <td></td>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style="height: 70px"></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td colspan="2">(............................)</td>
              <td colspan="2">(............................)</td>
              <td colspan="2"></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-else><br /><br /><br /><br /><br /></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      company: "",
      data: [],
      dataLine: [],
      supplier: [],
      cn_supplier: [],
    };
  },
  computed: {
    urlLogo: function () {
      return this.company == undefined ? "default.png" : this.company.company_logo;
    },
  },
  methods: {
    dateFormat(date) {
      if (date) {
        // let dt = new Date(date);
        // let d = dt.getDate();
        // let m = dt.getMonth() + 1;
        // let y = dt.getFullYear();
        // return d + "/" + m + "/" + y;
        return moment(date).format("D MMMM YYYY");
      }
    },
    cnSupplierValue(data) {
      console.log("data: ", data);
      if (data.cn_supplier.credit_note_supplier_line == undefined) {
        return;
      }

      this.cn_supplier = [];
      let chunkOffset = 0;

      for (let i = 0; i < data.cn_supplier.credit_note_supplier_line.length; i += 5) {
        let noteCopy = { ...data.cn_supplier };
        let currentChunk = data.cn_supplier.credit_note_supplier_line.slice(i, i + 5);
        noteCopy.manualLines = currentChunk;

        let totalPPH = currentChunk.reduce((sum, line) => {
          if (line.chart_of_account_name) {
            if (line.chart_of_account_name.toLowerCase().search("pph") >= 0) {
              return sum + (line.value_credit || 0);
            }
            return sum;
          }
        }, 0);
        let totalCredit = currentChunk.reduce((sum, line) => {
          if (line.value_credit > 0 && line.is_pph != true) {
            return sum + (line.value_credit || 0) + (line.vat_in_value || 0);
          }
          return sum;
        }, 0);
        noteCopy.chunkOffset = chunkOffset;

        noteCopy.totalPPH = totalPPH;
        noteCopy.total_credit = totalCredit;

        chunkOffset += currentChunk.length;
        this.cn_supplier.push(noteCopy);
      }
      console.log("data 1: ", this.cn_supplier);
      this.data = data.cn_supplier;
      this.dataLine = data.cn_supplier.credit_note_supplier_line;
      this.supplier = data.supplier;
      this.company = data.company;

      return true;
    },
  },
  components: {},
  mounted() {
    this.company = this.$userLogin;
    this.$http
      .get("/api/v2/finance/credit-note-supplier/" + this.$route.params.id, {
        params: {
          //id: this.$route.params.id
        },
      })
      .then((resp) => {
        if (resp.code == 200) {
          console.log("resp.data =>", resp.data);
          if (resp.data.cn_supplier) {
            let i_rec, i_vat_in;
            resp.data.cn_supplier.credit_note_supplier_line.forEach((el, i) => {
              if (el.receivable == true) {
                i_rec = i;
              }
              if (el.vat_in == true) {
                i_vat_in = i;
              }
              if (el.chart_of_account_name.toLowerCase().search("pph") >= 0) {
                resp.data.cn_supplier.credit_note_supplier_line[i].is_pph = true;
              } else {
                resp.data.cn_supplier.credit_note_supplier_line[i].is_pph = false;
              }
            });
            if (i_rec != undefined && i_vat_in != undefined) {
              resp.data.cn_supplier.credit_note_supplier_line[i_rec].vat_in_value =
                resp.data.cn_supplier.credit_note_supplier_line[i_vat_in].value_debit;
            }
            resp.data.cn_supplier.credit_note_supplier_line = resp.data.cn_supplier.credit_note_supplier_line.filter(
              (el) => {
                return el.value_credit > 0;
              }
            );
            this.cnSupplierValue(resp.data);
            this.$nextTick(() => {
              window.print();
            });
          } else {
            this.$vs.dialog({
              type: "alert",
              color: "danger",
              title: `Alert`,
              text: "Data not found",
            });
          }
          this.$vs.loading.close();
        } else {
          this.$vs.dialog({
            type: "alert",
            color: "danger",
            title: `Alert`,
            text: "Data not found",
          });
          this.$vs.loading.close();
        }
      });
  },
};
</script>

<style lang="scss">
body {
  height: 5.98in;
  width: 8.5in;
  margin: 0 auto;
  background-color: white;
  padding: 0;
  font-size: 16.5px;
  font-family: Times New Roman;
  //font-weight: bold;
  color: #000000;
}
div {
  margin-left: 0.3%;
  margin-right: 1.2%;
}

.d-flex {
  display: flex;
}

.color {
  background-color: #d0cdf2;
}

table.padding-table tr td {
  padding: 3px;
  font-family: Calibri;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

* {
  color-adjust: exact;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

// @page {
//   size: A5;
//   margin: 0;
// }

.popup {
  width: 100%;
  //height: 100%;
  position: absolute;
  top: 10;
  left: 0;
  display: none;
}

.popup:nth-of-type(2) {
  page-break-before: always;
  top: 100%;
}

@media print {
  .printable * {
    visibility: visible;
  }
  .popup {
    display: block;
  }
  .con-vs-dialog {
    display: none;
  }
  @page {
    size: 5.98in 8.5in;
    size: landscape;
    margin-left: 0.15in;
  }
}
</style>
